.range-field {
  margin-top: 1.4rem;
}
.input-range__slider {
  background: #4285f4;
  border: 1px solid #4285f4;
}
.input-range__track--active {
  background: #4285f4;
}
