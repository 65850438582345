.prompt-table {
  .btn-audio {
    font-size: 1.5rem;
    opacity: 0.4;
    &.active {
      color: #4382ff;
      opacity: 1;
      cursor: pointer;
    }
  }
}
.flowBuilder {
  width: 100%;
  height: 100vh;
  background: linear-gradient(#dceefd, rgba(255, 255, 255, 0) 1px),
    linear-gradient(90deg, #dceefd, rgba(255, 255, 255, 0) 1px);
  background-size: 10px 10px;
  background-position: 0 0;
  :global(.connection path:hover) {
    stroke: #fb3;
  }
}
