@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~bootstrap-css-only/css/bootstrap.min.css';
@import '~mdbreact/dist/css/mdb.css';
@import '~toastr/build/toastr.min.css';
@import '_breadcrumb';
@import '_button';
@import '_card';
@import '_form';
@import '_grid';
@import '_table';
@import '_size';
@import '_paragraph';
@import '_datepicker';
@import '_pagination';
@import '_slider';
@import '_json_pretty';
@import 'plugins/icons/feather';
html,
body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #535353;
  -webkit-font-smoothing: antialiased;
  background-color: #eee;
}
.spinner-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a {
  color: #4382ff;
}
.cp,
[role='button'] {
  cursor: pointer;
}
.form-control {
  &:focus {
    box-shadow: none !important;
  }
}
.loading-overlay {
  height: 100%;
  ._loading_overlay_overlay {
    background: transparent;
  }
}

@media (max-width: 991px) {
  html,
  body {
    font-size: 14px;
  }
}
