.lead-detail {
  label {
    font-weight: 300;
    font-size: 0.9rem;
    margin-bottom: 0;
  }
  .col-sm-6 {
    margin-bottom: 0.4rem;
  }
}
