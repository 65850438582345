.percentField {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :global(.input-field) {
    width: 50px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
