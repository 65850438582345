.breadcrumb {
  background: transparent;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}
.breadcrumb-item:not(.active) {
  color: #4382ff;
  cursor: pointer;
}
