.card {
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background-color: #ffffff;
  border: 0px;
}
.spinner-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
}
