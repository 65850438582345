.sorting {
  display: flex;
  font-size: 0.9rem;
  font-weight: 300;
  a {
    margin: 0;
    color: #212529;
  }
  td {
    background: white;
    border-bottom: 1px solid #dee2e6;
    display: block;
    padding: 0.75rem;
    padding-top: 1.1rem;
    padding-bottom: 1rem;
  }
  td:nth-child(1) {
    width: 100px;
  }
  td:nth-child(2) {
    flex: 1;
  }
  td:nth-child(3) {
    width: 130px;
  }
  td:nth-child(4) {
    width: 155px;
  }
  td:nth-child(5) {
    width: 130px;
  }
  td:nth-child(6) {
    width: 130px;
  }
  td:nth-child(7) {
    width: 130px;
  }
  td:nth-child(8) {
    width: 130px;
  }
  td:nth-child(9) {
    width: 110px;
  }
}
.row {
  position: relative;
}
.handler {
  position: absolute;
  left: 0;
  top: 35%;
  color: #777;
  font-size: 1rem;
  cursor: move;
}
