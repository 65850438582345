.phone-field {
  select {
    display: initial !important;
  }
  .react-phone-number-input__icon {
    margin-top: 2px;
  }
  .react-phone-number-input__icon-image {
    vertical-align: top;
  }
}
